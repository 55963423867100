import axios from 'axios';
import { httpErrorHandler } from './http-error-handler';

const api = axios.create();

const OK = 200
const MULTIPLE_CHOICES = 300

function responseHandler(response) {

  const config = response.config;
  
  if (config.raw)
    return response;

  if(response.status >= OK && response.status <= MULTIPLE_CHOICES)
    return response.data

  throw new Error('Erro ao realizar requisição: ' + response.statusText)

}

function responseErrorHandler(error) {

  const config = error.config;

  if (config && config.raw)
    return Promise.reject(error);

  return httpErrorHandler(error);

}

api.interceptors.response.use(responseHandler, responseErrorHandler);

export default api;
