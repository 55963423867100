import React, { useState, useEffect } from 'react';
import './Capa.css';

const Capa = (props) => {

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {

    if (props.imagem && props.imagem !== "null" && props.imagem !== "undefined" && props.imagem.trim() !== "") {

      setIsVisible(true);

    } else {
      
      setIsVisible(false);
    }

  }, [props.imagem]);

  return (
    <div className="capa">
      <img
        src={props.imagem}
        alt="Evento"
        className="capa-img"
        style={{ visibility: isVisible ? 'visible' : 'hidden' }}
      />
    </div>
  );
};

export default Capa;
