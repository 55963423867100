import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Home from "./screens/Home/Home"
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import { getToken } from "./services/AutenticacaoService"
import { getParametersCondiminium } from "./services/CondominioService"

function App() {

  useEffect(() => {
    // Função para buscar a cor do banco de dados
    const fetchColor = async () => {

      try {

        var token = await getToken()

        var parameters = await getParametersCondiminium(token)

        // Validação da cor
        if (/^#([0-9A-F]{3}|[0-9A-F]{6})$/i.test(parameters.corPrimaria))
          document.body.style.backgroundColor = parameters.corPrimaria; // Define a cor de fundo do body

      } catch (e) {
        console.log('Erro ao buscar a cor');
      }
    };

    fetchColor();

  }, []);

  return (

    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
