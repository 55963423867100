const { HttpError } = require('./http-error');

function httpErrorHandler(error) {
    if (error.response) {
        if (error.response.status === 400)
            throw new HttpError('Erro de solicitação inválida: o servidor não conseguiu entender a solicitação');

        throw new HttpError(`Erro: ${error.response.data.message || 'Erro desconhecido'}`);
    } else if (error.request) {
        if (error.code === 'ERR_NETWORK')
            throw new HttpError('Sem comunicação com a API. Verifique sua conexão de rede.');

        throw new HttpError('Erro na solicitação. Nenhuma resposta recebida da API.');
    } else {
        throw new HttpError(`Erro: ${error.message}`);
    }
}

module.exports = { httpErrorHandler };
