import React, { useState, useEffect } from 'react';
import Capa from '../../components/capa/Capa';
import Footer from '../../components/footer/Footer';
import InputMask from 'react-input-mask';
import "./Home.css"

import { getCondominium, getParametersCondiminium } from "../../services/CondominioService"
import { getToken } from "../../services/AutenticacaoService"
import { savePreRelease } from "../../services/PreLiberacaoService"
import { toast } from 'react-toastify';

const Home = () => {

    const [condominio, setCondominio] = useState(null)
    const [phone, setPhone] = useState('');
    const [backgroundButtonColor, setBackgroundButtonColor] = useState('#007BFF'); // Cor padrão azul

    const _3SEC = 3000;

    useEffect(() => {

        const fetchColor = async () => {

            try {

                var token = await getToken()

                var parameters = await getParametersCondiminium(token)

                var primaryColor = parameters.corPrimaria

                // Validação da cor
                if (/^#([0-9A-F]{3}|[0-9A-F]{6})$/i.test(primaryColor))
                    setBackgroundButtonColor(primaryColor);

            } catch (error) {

                toast.error(error.message)

                console.error(error.message, error);
            }

        };

        async function handleCondominium() {

            try {

                const token = await getToken()

                const condominio = await getCondominium(token)

                setCondominio(condominio);

            } catch (error) {

                toast.error(error.message)

                console.error(error.message, error);
            }

        }

        handleCondominium();
        fetchColor();

    }, []);

    const [formData, setFormData] = useState({
        telefone: '',
        nome: '',
        empresa: '',
        profissao: '',
        informacoesAdicionais: '',
        email: '',
        areasInteresse: [],
        autorizoUsoDados: true // Adicionado para LGPD
    });

    const CondominioDescricao = ({ condominio }) => {

        const descricao = condominio ? condominio.descricao : ''

        return (
            <div className="descricao">

                <h2>Descrição do Evento</h2>

                <div className="html-display">
                    <div dangerouslySetInnerHTML={{ __html: descricao }} />
                </div>

            </div>
        );
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === "checkbox") {
            if (name === 'autorizoUsoDados') {
                setFormData({ ...formData, [name]: checked });
            } else {
                setFormData((prev) => ({
                    ...prev,
                    areasInteresse: checked
                        ? [...prev.areasInteresse, value]
                        : prev.areasInteresse.filter(area => area !== value)
                }));
            }
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            formData.telefone = removeSpecialCharacters(phone || '').replace(' ', '')

            var isValid = true;

            if (!formData.autorizoUsoDados) {
                toast.warn('É preciso autorizar o uso dos dados para realizar o cadastro')
                isValid = false
            }

            if (formData.telefone.length < 10) {
                toast.warn('Telefone deve conter no mínimo 10 digitos')
                isValid = false
            }

            if (!isValid)
                return

            await savePreRelease(formData, condominio, await getToken())

            toast.success('Cadastro realizado com sucesso. Obrigado por se credenciar')

            setTimeout(function () {
                handleRedirect()
            }, _3SEC);

        } catch (e) {

            toast.error(e.message);

            console.log(e)
        }
    };

    function removeSpecialCharacters(texto) {
        return texto.replace(/[^a-zA-Z0-9 ]/g, '');
    }

    const handleRedirect = () => {
        window.location.href = 'https://www.feiraconstruvale.com.br'; // Altere para o seu URL desejado
    };

    const handlePhoneChange = (e) => {
        console.log(e)
        setPhone(e.target.value);
    };

    return (
        <div className="app-container">
            {condominio && <Capa imagem={condominio.imagemCapa} />}
            <div className="content-container">
                <CondominioDescricao condominio={condominio} />
                <form className="credenciamento-form" onSubmit={handleSubmit}>
                    <h3>Credencie-se no Evento</h3>
                    <InputMask
                        mask="(99) 99999-9999"
                        value={phone}
                        onChange={handlePhoneChange}
                        className="custom-input"
                        placeholder="Telefone"
                        required

                    />

                    <input
                        type="text"
                        maxLength={100}
                        name="nome"
                        placeholder="Nome"
                        value={formData.nome}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="email"
                        maxLength={100}
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                    />

                    <input
                        type="text"
                        maxLength={100}
                        name="empresa"
                        placeholder="Empresa"
                        value={formData.empresa}
                        onChange={handleChange}
                    />

                    <input
                        type="text"
                        maxLength={100}
                        name="profissao"
                        placeholder="Profissão"
                        value={formData.profissao}
                        onChange={handleChange}
                    />

                    {/* Agrupamento das áreas de interesse */}
                    <div className="areas-interesse">
                        <h4>Áreas de Interesse no Evento</h4>
                        <div className="checkbox-group">
                            <label>
                                <input
                                    type="checkbox"
                                    name="areasInteresse"
                                    value="Quero comprar um imóvel"
                                    onChange={handleChange}
                                /> Quero comprar um imóvel
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    name="areasInteresse"
                                    value="Quero fazer negócios"
                                    onChange={handleChange}
                                /> Quero fazer negócios
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    name="areasInteresse"
                                    value="Ampliar meu networking"
                                    onChange={handleChange}
                                /> Quero ampliar meu networking
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    name="areasInteresse"
                                    value="Apenas passeio"
                                    onChange={handleChange}
                                /> Apenas passeio
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    name="areasInteresse"
                                    value="Outros"
                                    onChange={handleChange}
                                /> Outros
                            </label>
                        </div>
                    </div>

                    <p></p>
                    {/* Checkbox para LGPD */}
                    <label className="lgpd-checkbox">
                        <input
                            type="checkbox"
                            name="autorizoUsoDados"
                            checked={formData.autorizoUsoDados}
                            onChange={handleChange}
                        /> Autorizo o uso dos dados
                    </label>

                    <button type="submit" style={{ backgroundColor: backgroundButtonColor }}>Enviar</button>
                    <p><em>*Quem puder levar 1Kg de alimento não perecível, o mesmo será destinado a uma entidade social</em></p>
                </form>
                <Footer /> {/* Footer agora está dentro da content-container */}
            </div>
        </div>
    );
}

export default Home;


