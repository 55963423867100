import React from 'react';
import './Footer.css';
import logoempresa from '../../assets/logo-control2.png';
import logovalltech from '../../assets/logo-valltech.png';

const Footer = () => {
  return (
    <footer className="footer">
      <p>Desenvolvido por:</p>
      <div className="footer-logos">
        <img 
          src={logoempresa} 
          alt="CONTROL2 SISTEMAS"
          className="footer-logo-control2"
        />
        <img 
          src={logovalltech} 
          alt="VALLTECH"
          className="footer-logo-valltech"
        />
      </div>
    </footer>
  );
};

export default Footer;
