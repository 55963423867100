import { postPreRealese, getarametersCondominiumPBydCondominium } from '../integrations/cap/capClient'

const TELEFONE = 0

export const savePreRelease = async (formData, condominio, token) => {

    const parametros = await getarametersCondominiumPBydCondominium(condominio, token)

    var preLiberacao = {
        pessoaVisitante: {
            nome: formData.nome,
            email: formData.email,
            observacao: formData.informacoesAdicionais,
            telefones: [
                {
                    numero: formData.telefone,
                    "tipo": TELEFONE
                }
            ],
            documentos: []
        },
        empresaVisitante: formData.empresa,
        profissaoVisitante: formData.profissao,
        idPessoaIntegrante: parametros.pessoaPadrao.id,
        idUnidade: parametros.unidadePadrao.id,
        observacao: formData.areasInteresse.join(", ")
    }

    return await postPreRealese(preLiberacao, token)

}