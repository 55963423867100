import { postAutenticar, getValidate } from '../integrations/cap/capClient'

export const getToken = async () => {

    let token = localStorage.getItem('TOKEN') || ''

    if(!token)
        return await generateToken()

    let isValid = await getValidate(token)

    if(isValid){
        localStorage.setItem('TOKEN', token);
        return token
    }

    return await generateToken()
}

const generateToken = async () =>{

    const token = await postAutenticar()
    localStorage.setItem('TOKEN', token);

    return token
}