import { getCondominiumById, getarametersCondominiumPBydCondominium } from '../integrations/cap/capClient'
import { config } from '../Config'

export const getCondominium = async (token) => {

    return getCondominiumById(config.idCondominio, token)
}


export const getParametersCondiminium = async (token) => {

    var condominio = await getCondominium(token)

    return await getarametersCondominiumPBydCondominium(condominio, token)
}
