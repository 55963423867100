import api from '../../api/axios-config.js';
import { config } from '../../Config.js'

const GET_CONDOMINIO_ENDPOINT = "/api/condominio/"
const GET_VALIDATE_ENDPOINT = "/api/autenticacao/validar"
const POST_AUTENTICAR_ENDPOINT = "/api/autenticacao"
const POST_PRE_LIBERACAO_ENDPOINT = "/api/pre_liberacao"
const GET_PARAMETROS_CONDOMINIO = "/api/condominio/{0}/parametros"

export const postAutenticar = async () => {

    const url = config.urlApi
    const endpoint = POST_AUTENTICAR_ENDPOINT

    const payload = {
        username: config.usuario,
        password: config.senha
    }

    const response = await api.post(`${url}${endpoint}`, payload)

    if (!response)
        throw new Error('Não foi realizar a autenticação')

    return response.token
}

export const getValidate = async (token) => {

    const url = config.urlApi
    const endpoint = GET_VALIDATE_ENDPOINT

    try {

        const response = await api.get(`${url}${endpoint}`, handleAutthentication(token));

        return response

    } catch (e) {

        console.log('Não foi possível testar a validação do token')
        console.log(e)

        return false
    }
};

export const getCondominiumById = async (id, token) => {

    const url = config.urlApi
    const endpoint = GET_CONDOMINIO_ENDPOINT + id

    const response = await api.get(`${url}${endpoint}`, handleAutthentication(token));

    if (!response)
        throw new Error('Não foi possível pesquisar as informações iniciais')

    return response
};

export const getarametersCondominiumPBydCondominium = async (condominio, token) => {

    const url = config.urlApi
    const endpoint = GET_PARAMETROS_CONDOMINIO.replace('{0}', condominio.id)

    const response = await api.get(`${url}${endpoint}`, handleAutthentication(token));

    if (!response)
        throw new Error('Não foi possível pesquisar os parâmetros')

    return response
};

export const postPreRealese = async (preLiberacao, token) => {

    const url = config.urlApi
    const endpoint = POST_PRE_LIBERACAO_ENDPOINT
    const payload = preLiberacao

    const response = await api.post(`${url}${endpoint}`, payload, handleAutthentication(token))

    if (!response)
        throw new Error('Não foi possível a pessoa com nome' + preLiberacao.nome)

    return response
}

const handleAutthentication = (token) => {

    return {
        headers: { Authorization: `Bearer ${token}` }
    };

}